import { RequiredAuthProvider } from '@propelauth/react';
import * as Sentry from '@sentry/react';
import { Provider as JotaiProvider, useSetAtom } from 'jotai';

import hounddogLogoBlack from '/logo-b.png';

import { useCallback } from 'react';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  isRouteErrorResponse,
  useRouteError,
} from 'react-router';
import type { MetaFunction } from 'react-router';

import LeftSidebar from '~/components/left-sidebar';
import TopHeader from '~/components/top-header';
import { ScrollArea, ScrollBar } from '~/components/ui/scroll-area';
import { Toaster } from '~/components/ui/toaster';
import { TooltipProvider } from '~/components/ui/tooltip';
import globalStyles from '~/global-styles.css?url';

import LoadingAnimation from './components/loading-animation';
import { Button } from './components/ui/button';
import { getMetadata } from './lib/api-services';
import { jotaiStore, metadataAtom } from './lib/jotai-atoms';
import { usePolling } from './lib/use-polling';

//
// html metadata tags
//
export const meta: MetaFunction = () => {
  return [
    { title: 'HoundDog.ai' },
    {
      name: 'description',
      content: 'Code Scanner to Detect Sensitive Data Mishandling',
    },
  ];
};

//
// html header links elements
//
export const links = () => {
  return [
    { rel: 'stylesheet', href: globalStyles },
    { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
    {
      rel: 'preconnect',
      href: 'https://fonts.gstatic.com',
      crossOrigin: '',
    },
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Open%20Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap',
    },
  ];
};

//
// HydrateFallback and main loading screen
//
export function HydrateFallback() {
  return (
    <div className='flex min-h-screen flex-col items-center justify-center bg-gray-100'>
      <LoadingAnimation size='large' />
    </div>
  );
}

//
// ErrorBoundary
//
export function ErrorBoundary() {
  const error = useRouteError();

  let is404 = false;
  if (isRouteErrorResponse(error) && error.status === 404) {
    is404 = true;
  }

  if (error && error instanceof Error && !is404) {
    // you only want to capture non 404-errors that reach the boundary
    Sentry.captureException(error);
  }

  return (
    <div className='flex min-h-screen flex-col items-center justify-center bg-gray-100'>
      <img
        src={hounddogLogoBlack}
        alt='hounddog.ai logo'
        className='max-w-24'
      />
      <h2 className='mt-6 text-3xl font-medium'>Oops</h2>
      <p className='mt-4 text-lg'>Something went wrong</p>
      {is404 && (
        <>
          <p className='mt-4 text-lg font-medium'>404</p>
          <p className='text-lg'>Resource not found</p>
        </>
      )}
      <Button asChild className='mt-6' size='lg'>
        <a href='/'>Take me Home</a>
      </Button>
    </div>
  );
}

//
// Custom Providers wrapper
//
function Providers({ children }: { children: React.ReactNode }) {
  return (
    <RequiredAuthProvider authUrl={import.meta.env.VITE_AUTH_URL}>
      <JotaiProvider store={jotaiStore}>
        <TooltipProvider>{children}</TooltipProvider>
      </JotaiProvider>
    </RequiredAuthProvider>
  );
}

//
// Main Root Layout
//
export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang='en'>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <Meta />
        <Links />
      </head>
      <body>
        <Providers>{children}</Providers>
        <Scripts />
      </body>
    </html>
  );
}

//
// Main App - (Root Layout children)
//
function App() {
  const setMetadata = useSetAtom(metadataAtom);

  //
  // APP GLOBAL STATE - POLLING REFRESH EVERY 5 MINUTES
  //
  const pollMetadataFn = useCallback(async () => {
    const metadata = await getMetadata();
    setMetadata(metadata);
  }, [setMetadata]);

  usePolling(pollMetadataFn, 5 * 60 * 1000, true);

  return (
    <>
      <div className='flex h-screen'>
        <LeftSidebar />
        <ScrollArea type='auto' className='flex-1 bg-gray-50'>
          <TopHeader />
          <div className='container mt-14 p-4'>
            <Outlet />
          </div>
          <ScrollBar orientation='horizontal' />
        </ScrollArea>
      </div>
      <Toaster />
    </>
  );
}

export default App;
