import { useAuthInfo } from '@propelauth/react';
import { useSetAtom } from 'jotai';
import {
  BookA,
  FileLock2,
  FolderGit2,
  KeyRound,
  ScrollText,
  Settings,
  ShieldX,
  TextSearch,
} from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router';

import hounddogLogoWhite from '/logo-w.png';

import { Separator } from '~/components/ui/separator';
import { currentOrganizationIdAtom } from '~/lib/jotai-atoms';
import { useAuthorization } from '~/lib/use-authorization';
import { cn, getStoredOrganizationId } from '~/lib/utils';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from './ui/accordion';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select';

type RulesAccordionState = 'rules-and-definitions' | '';

export default function LeftSidebar() {
  const authInfo = useAuthInfo();
  const setCurrentOrganizationId = useSetAtom(currentOrganizationIdAtom);

  const userOrganizations = authInfo?.orgHelper?.getOrgs() || [];
  const storedOrganizationId = getStoredOrganizationId(userOrganizations);

  const onOrganizationChange = (newOrgId: string) => {
    setCurrentOrganizationId(newOrgId);
    window.location.href = '/';
  };

  const hasManyOrganizations = userOrganizations.length > 1;
  const { hasPermission } = useAuthorization();

  // EFFECT: Set the state of the Rules accordion to open if the user is on a page that is a child of the Rules accordion
  const location = useLocation();
  const [rulesAccordionState, setRulesAccordionState] =
    useState<RulesAccordionState>('');
  useEffect(() => {
    const rulesAccordionPaths = [
      '/data-elements/',
      '/sanitizers/',
      '/scan-rules/',
    ];
    const shouldOpenRulesAccordion = rulesAccordionPaths.some((path) =>
      location.pathname.includes(path),
    );
    if (shouldOpenRulesAccordion) {
      setRulesAccordionState('rules-and-definitions');
    }
  }, [location.pathname]);

  return (
    <aside className='w-60 bg-hounddog-bg p-3 text-white'>
      {/*
           LOGO
       */}
      <Link
        to='/repositories/'
        className='mb-4 flex items-center gap-2'
        state={{ resetPagination: true }}
      >
        <img
          src={hounddogLogoWhite}
          alt='HoundDog.ai Logo'
          className='h-[39px] w-[29px]'
        />
        <span className='text-xl'>
          <span className='font-bold'>Hound</span>Dog.ai
        </span>
      </Link>

      {/*
           ORGANIZATION SELECTOR
       */}
      {hasManyOrganizations ? (
        <Select
          value={storedOrganizationId}
          onValueChange={onOrganizationChange}
        >
          <SelectTrigger
            className='mt-3 h-8 bg-inherit text-sm italic'
            id='organizationSelect'
          >
            <SelectValue placeholder='Organization' />
          </SelectTrigger>
          <SelectContent>
            {userOrganizations
              .sort((a, b) => a.orgName.localeCompare(b.orgName))
              .map((org) => (
                <SelectItem key={org.orgId} value={org.orgId}>
                  {org.orgName}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      ) : null}

      {/*
           REPOSITORIES
       */}
      {hasPermission('repositories:read') && (
        <NavLink
          to='/repositories/'
          className={({ isActive, isPending }) =>
            cn(
              'mt-2 flex items-center gap-3 p-2 hover:rounded-xl hover:bg-gray-400',
              { 'font-bold': isPending || isActive },
            )
          }
          state={{ resetPagination: true }}
        >
          <FolderGit2 size={20} />
          <span>Repositories</span>
        </NavLink>
      )}
      {/*
           VULNERABILITIES
       */}
      {hasPermission('dataflows:read') && (
        <NavLink
          to='/vulnerabilities/?status=open'
          className={({ isActive, isPending }) =>
            cn(
              'mt-2 flex items-center gap-3 p-2 hover:rounded-xl hover:bg-gray-400',
              { 'font-bold': isPending || isActive },
            )
          }
          state={{ resetPagination: true }}
        >
          <ShieldX size={20} />
          <span>Vulnerabilities</span>
        </NavLink>
      )}

      {/*
           SENSIIVE DATAMAP
       */}
      {hasPermission('data-elements:read') && (
        <NavLink
          to='/sensitive-datamap/'
          className={({ isActive, isPending }) =>
            cn(
              'mt-2 flex items-center gap-3 p-2 hover:rounded-xl hover:bg-gray-400',
              { 'font-bold': isPending || isActive },
            )
          }
          state={{ resetPagination: true }}
        >
          <TextSearch size={20} />
          <span>Sensitive Datamap</span>
        </NavLink>
      )}

      {/*
            PRIVACY REPORTS
       */}
      {hasPermission('data-elements:read') &&
        hasPermission('ropa-reports:read') && (
          <NavLink
            to='/privacy-reports/'
            className={({ isActive, isPending }) =>
              cn(
                'mt-2 flex items-center gap-3 p-2 hover:rounded-xl hover:bg-gray-400',
                { 'font-bold': isPending || isActive },
              )
            }
            state={{ resetPagination: true }}
          >
            <FileLock2 size={20} />
            <span>Privacy Reports</span>
          </NavLink>
        )}

      {(hasPermission('data-elements:read') ||
        hasPermission('sanitizers:read') ||
        hasPermission('scan-rules:read')) && (
        <Accordion
          type='single'
          collapsible
          className='w-full'
          value={rulesAccordionState}
          onValueChange={(val: RulesAccordionState) => {
            setRulesAccordionState(val);
          }}
        >
          <AccordionItem value='rules-and-definitions' className='border-none'>
            <AccordionTrigger className='mt-2 p-0 hover:rounded-xl hover:bg-gray-400 hover:no-underline'>
              <div className='flex items-center gap-3 p-2'>
                <BookA size={20} className='!transform-none' />
                <span className='font-normal'>Rules &amp; Definitions</span>
              </div>
            </AccordionTrigger>
            <AccordionContent className='flex flex-col gap-3 pb-0 pt-2'>
              {/*
                DATA ELEMENTS
            */}
              {hasPermission('data-elements:read') && (
                <NavLink
                  to='/data-elements/'
                  className={({ isActive, isPending }) =>
                    cn(
                      'p-2 pl-11 text-base hover:rounded-xl hover:bg-gray-400',
                      {
                        'font-bold': isPending || isActive,
                      },
                    )
                  }
                  state={{ resetPagination: true }}
                >
                  Data Elements
                </NavLink>
              )}

              {/*
                SANITIZERS
            */}
              {hasPermission('sanitizers:read') && (
                <NavLink
                  to='/sanitizers/'
                  className={({ isActive, isPending }) =>
                    cn(
                      'p-2 pl-11 text-base hover:rounded-xl hover:bg-gray-400',
                      {
                        'font-bold': isPending || isActive,
                      },
                    )
                  }
                  state={{ resetPagination: true }}
                >
                  Sanitizers
                </NavLink>
              )}

              {/*
                SCAN RULES
            */}
              {hasPermission('scan-rules:read') && (
                <NavLink
                  to='/scan-rules/'
                  className={({ isActive, isPending }) =>
                    cn(
                      'p-2 pl-11 text-base hover:rounded-xl hover:bg-gray-400',
                      {
                        'font-bold': isPending || isActive,
                      },
                    )
                  }
                >
                  Scan Rules
                </NavLink>
              )}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      )}

      {/* API KEYS */}
      <NavLink
        to='/api-keys/'
        className={({ isActive, isPending }) =>
          cn(
            'mt-2 flex items-center gap-3 p-2 hover:rounded-xl hover:bg-gray-400',
            { 'font-bold': isPending || isActive },
          )
        }
      >
        <KeyRound size={20} />
        <span>API Keys</span>
      </NavLink>

      {/* AUDIT LOGS */}
      {hasPermission('audit-logs:read') && (
        <NavLink
          to='/audit-logs/'
          className={({ isActive, isPending }) =>
            cn(
              'mt-2 flex items-center gap-3 p-2 hover:rounded-xl hover:bg-gray-400',
              { 'font-bold': isPending || isActive },
            )
          }
        >
          <ScrollText size={20} />
          <span>Audit Logs</span>
        </NavLink>
      )}

      <Separator className='my-4' />

      {/*
           SETTINGS
       */}
      <NavLink
        to='/settings/'
        className={({ isActive, isPending }) =>
          cn(
            'mt-2 flex items-center gap-3 p-2 hover:rounded-xl hover:bg-gray-400',
            { 'font-bold': isPending || isActive },
          )
        }
      >
        <Settings size={20} />
        <span>Settings</span>
      </NavLink>
    </aside>
  );
}
